<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :extra-actions="extraActions"
    :filters="filters"
    :filter-vals.sync="filterVals"
    single-edit
    authority="Users_Company"
    inline-actions
  />
</template>

<script>
import { canPublishToShop } from '@/auth/utils'
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../companyStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      countryOptions: [],
      coverAreaOptions: [],
      roleOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'companies',
        endpoint: 'companies',
        route: 'companies',
        title: {
          single: this.$i18n.t('Company'),
          plural: this.$i18n.t('Companies'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'published', label: '' },
        { key: 'logo', label: this.$i18n.t('logo'), sortable: false },
        { key: 'title', label: this.$i18n.t('name'), sortable: true },
        { key: 'email', label: this.$i18n.t('email'), sortable: true },
        { key: 'roles', label: this.$i18n.t('roles'), sortable: false },
        { key: 'enabled', label: this.$i18n.t('enabled') },
        { key: 'actions', label: this.$i18n.t('actions') },
      ]
    },

    formattedColumns() {
      return [
        { key: 'published', type: 'status' },
        { key: 'logo', type: 'logo' },
        { key: 'enabled', type: 'boolean' },
        { key: 'roles', type: 'list' },
      ]
    },

    extraActions() {
      const canPublish = canPublishToShop()
      return [
        {
          endpoint: 'eshop/publish',
          icon: 'UploadCloudIcon',
          title: 'Publish to e-shop',
          renderCondition: {
            key: 'published',
            value: false,
          },
          authorityValidation: canPublish,
        },
        {
          endpoint: 'eshop/publish',
          icon: 'UploadCloudIcon',
          title: 'Update e-shop',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: canPublish,
        },
        {
          endpoint: 'eshop/unpublish',
          icon: 'DownloadCloudIcon',
          title: 'Remove from e-shop',
          variant: 'danger',
          renderCondition: {
            key: 'published',
            value: true,
          },
          authorityValidation: canPublish,
        },
      ]
    },

    filters() {
      return [
        {
          name: 'country',
          label: this.$t('country'),
          options: this.countryOptions,
        },
        {
          name: 'state',
          label: this.$t('State'),
          options: this.coverAreaOptions,
        },
        {
          name: 'role',
          label: this.$t('role'),
          options: this.roleOptions,
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('enabled'),
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          maxDateFieldIdx: '5',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '4',
          maxDate: new Date(),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCoverAreas').then(response => {
      this.coverAreaOptions = response.data
    })
    this.$store.dispatch('app-common/fetchRoles').then(response => {
      this.roleOptions = response.data
    })
  },
}
</script>
